<template>
  <div class="roomsCategory" :style="background">
    <div class="top"><p>{{packages}}</p></div>
    <div class="bottom"><p>${{price}}</p></div>
  </div>
</template>

<script>
export default {
  props: {
    background: Array[""],
    price:String,
    packages:String,

  },
};
</script>

<style scoped>
.roomsCategory {
  position: relative;
  height: 348px;
  width: 505px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.top {
  position: absolute;
  top: 0;
  right: 0;
  height: 348px;
  width: 505px;
  background: linear-gradient(156.23deg, #fff 10%, rgba(122, 108, 108, 0) 38%);
}
.bottom {
  height: 348px;
  width: 505px;
  background: linear-gradient(156.23deg, #fff 10%, rgba(255, 255, 255, 0) 31%);
  transform: rotate(180deg);
}
.bottom p{
  float: left;
  /* background: blue; */
  transform: rotate(180deg);
  font-family: Raleway;
  font-size: 40px;
  font-weight: 600;
  line-height: 47px;
  text-align: center;
}
p{
  margin: 20px;
  font-family: Courgette;
  font-size: 40px;
  line-height: 50px;
}
@media screen and (max-width:720px){
  .roomsCategory {
  height: 348px;
  width: 100%;
  background-color: aqua;
}
.top {
  height: 348px;
  width: 100%;
}
.bottom {
  height: 348px;
  width: 100%;
}
}
</style>