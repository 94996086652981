<template>
  <div>
    <div class="info">
      <img :src="FacilityIcon" a/>
      <div class="desc">
        <p>{{ FacilityDesc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    FacilityIcon: String,
    FacilityDesc: String,
  },
};
</script>

<style scoped>
.info {
  display: flex;
}
img {
  width: 42px;
  margin-top: -10px;
}
.info p {
  margin-left: 10px;
  color: #333;
  font-family: Raleway;
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
}
.desc {
  display: grid;
  place-items: center;
}
@media screen and (max-width:720px){
  .info {
  display: grid;
  place-items: center;
}
.info p {
  font-size: 20px;
}
}
</style>