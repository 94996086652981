<template>
  <Header />
  <Hero
    :header="hero.header"
    :h1Style="hero.h1Style"
    :HeaderSection="hero.HeaderSection"
    :backgroundStyle="hero.backgroundStyle"
  />
  <h1>Facilities</h1>
  <div class="facilities">
    <Facilities
      class="facilityDetails"
      v-for="detail in details"
      :key="detail.id"
      :FacilityIcon="detail.FacilityIcon"
      :FacilityDesc="detail.FacilityDesc"
    />
  </div>
  <div class="rooms">
    <RoomsCategory
      class="roomDetails"
      v-for="room in rooms"
      :key="room.id"
      :background="room.background"
      :price="room.price"
      :packages="room.packages"
    />
  </div>
  <Footer />
</template>

<script>
import Header from "../components/layouts/Header";
import Hero from "../components/Hero";
import Facilities from "../components/OurRooms/Facilities";
import RoomsCategory from "../components/OurRooms/RoomsCategory";
import Footer from "../components/layouts/Footer";
export default {
  components: {
    Header,
    Hero,
    Facilities,
    RoomsCategory,
    Footer,
  },
  data() {
    return {
      hero: {
        header: "Our Rooms",
        backgroundStyle: {
          backgroundImage: `url(${require("../assets/OurRooms/ourrooms-bg.png")})`,
        },
        h1Style: {
          // width: "500px",
          color: "#006233",
          fontize: "80px",
          textAlign: "right",
        },
        HeaderSection: {
          backgroundColor: "rgba(200,200,200,0.59)",
        },
      },
      details: [
        {
          FacilityIcon: require("../assets/icons/wifi.png"),
          FacilityDesc: "Free Wi-Fi",
        },
        {
          FacilityIcon: require("../assets/icons/laundry_service.png"),
          FacilityDesc: "Laundry Services",
        },
        {
          FacilityIcon: require("../assets/icons/parking.png"),
          FacilityDesc: "Free Parking",
        },
        {
          FacilityIcon: require("../assets/icons/restaurant.png"),
          FacilityDesc: "Free Breakfast",
        },
        {
          FacilityIcon: require("../assets/icons/bar.png"),
          FacilityDesc: "Bar / Lounge",
        },
        {
          FacilityIcon: require("../assets/icons/pets.png"),
          FacilityDesc: "Pet Friendly",
        },
      ],
      rooms: [
        {
          background: {
            backgroundImage: `url(${require("../assets/OurRooms/accasia.png")})`,
          },
          packages: "Accasia",
          price: "13",
        },
        {
          background: {
            backgroundImage: `url(${require("../assets/OurRooms/simba.png")})`,
          },
          packages: "Simba",
          price: "13",
        },
        {
          background: {
            backgroundImage: `url(${require("../assets/OurRooms/twiga.png")})`,
          },
          packages: "Twiga",
          price: "13",
        },
        {
          background: {
            backgroundImage: `url(${require("../assets/OurRooms/swala.png")})`,
          },
          packages: "Swala",
          price: "13",
        },
        {
          background: {
            backgroundImage: `url(${require("../assets/OurRooms/tembo.png")})`,
          },
          packages: "Tembo",
          price: "13",
        },
        {
          background: {
            backgroundImage: `url(${require("../assets/OurRooms/nala.png")})`,
          },
          packages: "Nala",
          price: "13",
        },
      ],
    };
  },
};
</script>

<style scoped>
.facilities {
  padding: 0px 0px 50px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1300px;
  margin: auto;
}
.facilityDetails {
  flex: 0 300px;
  margin: 20px;
}
h1 {
  padding: 80px 0px 50px 0px;
  color: #333;
  font-size: 44px;
  line-height: 55px;
  text-align: center;
}
.rooms {
  padding: 80px 0px 80px 0px;
  background-color: #006233;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.roomDetails {
  margin: 20px;
}
@media screen and (max-width:720px){
  .facilities {
  display: grid;
  place-items: center;
}
}
</style>